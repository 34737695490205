import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import GlobalStyle from "./styles/globalstyle";
import DetailItems from "./pages/DetailItems";
import Index from "./pages";
import { deleteAction, SidebarAction } from "./api/action";
import { SidebarLoader } from "./api/loader";
import Error from "./pages/Error";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<App />}
        loader={SidebarLoader}
        action={SidebarAction}
        errorElement={<Error />}
      >
        <Route errorElement={<Error />}>
          <Route index element={<Index />} />
          <Route path="detail/:url" element={<DetailItems />} />
          <Route path="detail/:url/delete" action={deleteAction} />
        </Route>
      </Route>
    </>
  )
);
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <RouterProvider router={router} />
  </ThemeProvider>
);

reportWebVitals();
