import { createGlobalStyle } from "styled-components";

export interface DefaultTheme {
  colors: {
    white: string;
    white000: string;
    white100: string;

    gray000: string;
    primary: string;
  };
}

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  html,
  body {
    font-size: 16px;
    width: 100vw;
    height: 100vh;
  }
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  input,
  button {
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    background-color: ${({ theme }) => theme.colors.white};
  }

  button {
    cursor: pointer;
  }

  i {
  color: ${({ theme }) => theme.colors.gray000};
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;

    &:link {
      color: black;
    }
  }
  
`;

export default GlobalStyle;
