import { LoadingOutlined } from "@ant-design/icons";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { FlexCenter } from "../styles/shared";

interface Props {
  spinning: boolean;
  children: ReactNode;
}

const CustomSpinner: React.FC<Props> = ({ spinning, children }) => {
  return (
    <Container spinning={spinning}>
      <Spinner hidden={!spinning}>
        <LoadingOutlined style={{ fontSize: "5rem", color: "black" }} />
      </Spinner>
      {children}
    </Container>
  );
};

export default CustomSpinner;

interface StyleProps {
  spinning: boolean;
}

const Container = styled.div<StyleProps>`
  ${FlexCenter}
  pointer-events: ${({ spinning }) => (spinning ? "none" : "default")};
`;

const Spinner = styled.div`
  position: absolute;
  z-index: 999;
`;
