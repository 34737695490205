import React, { ForwardedRef, forwardRef } from "react";
import styled, { keyframes } from "styled-components";
import { FlexCenter } from "../styles/shared";

const Loading = forwardRef(function Loading(
  _,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Container ref={ref}>
      <Spinner />
    </Container>
  );
});

export default Loading;

const Container = styled.div`
  ${FlexCenter}
  width: 100%;
  height: 16rem;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 3rem;
  height: 3rem;
  border: 5px solid ${({ theme }) => theme.colors.primary};
  border-top: 5px solid ${({ theme }) => theme.colors.white};
  animation: ${spin} 1.1s infinite linear;
  border-radius: 50%;
`;
