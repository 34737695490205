import { DefaultTheme } from "./globalstyle";

const colors = {
  white: "#ffffff",
  white000: "#f7f7f7",
  white100: "#e3e3e3",

  gray000: "#818181",
  primary: "#1677ff",

  primaryOpacity000: "rgba(22, 119, 255, 0.4)",
};

const theme: DefaultTheme = {
  colors,
};

export { theme };
