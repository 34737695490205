import { createSiteTypes } from "../types";

export const getLocalStorage = (): string[] => {
  const sites = localStorage.getItem("sites");

  if (!sites) {
    return [];
  }

  return JSON.parse(sites);
};

export const setLocalStorage = (url: string) => {
  const sites = localStorage.getItem("sites");

  if (!sites) {
    localStorage.setItem("sites", JSON.stringify([url]));
    return;
  }

  const parsed = JSON.parse(sites);
  parsed.push(url);
  localStorage.setItem("sites", JSON.stringify(parsed));
};

// 관심 사이트 불러오기.
export function getSites(): string[] {
  const sites = localStorage.getItem("sites");

  if (!sites) {
    return [];
  }

  return JSON.parse(sites);
}

// 관심 사이트 등록 가능한지 체크.
export async function createSite(url: string) {
  const res = await fetch(`/wayback/available?url=${url}`);
  const data = (await res.json()) as createSiteTypes;

  if (data.archived_snapshots.hasOwnProperty("closest")) {
    return true;
  }

  return false;
}

// 관심 사이트 상세 목록 불러오기.
export async function getSiteDetail(
  url: string,
  resumeKey?: string
): Promise<{ detailList: string[][]; resumeKey: string }> {
  let query = ``;

  if (resumeKey) {
    query = `&resumeKey=${resumeKey}`;
  }

  const res = await fetch(
    `/cdx/search/cdx?url=${url}&from=2013&to=2023&fl=timestamp,original&output=json&limit=50&showResumeKey=true` +
      query
  );

  const data = (await res.json()) as string[][];
  const detailList = data.slice(1);
  const key = detailList.pop() as string[];
  detailList.pop();

  return { detailList, resumeKey: key[0] };
}

// 관심 사이트 삭제.
export function deleteSite(url: string) {
  const sites = localStorage.getItem("sites");

  if (sites) {
    const parsed = JSON.parse(sites) as string[];
    const newSites = parsed.filter((site) => site !== url);
    localStorage.setItem("sites", JSON.stringify(newSites));
  }
}
