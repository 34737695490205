import React, { memo } from "react";
import styled from "styled-components";

interface Props {
  url: string;
  date: string;
}

const DetailItem: React.FC<Props> = ({ url, date }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Container>
        <FlexBox>{url}</FlexBox>
        <Date>{date}</Date>
      </Container>
    </a>
  );
};

export default memo(DetailItem);

const Container = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryOpacity000};
    font-weight: bold;
  }
`;

const FlexBox = styled.div`
  min-width: 31rem;
`;

const Date = styled.div`
  font-size: 0.75rem;
  margin-left: 1rem;
`;
