import styled from "styled-components";
import Slidebar from "./components/Sidebar";
import { Outlet, useNavigation } from "react-router-dom";

function App() {
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading" ? true : false;

  return (
    <Container>
      <Slidebar />
      <Main isLoading={isLoading}>
        <Outlet />
      </Main>
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
`;

const Main = styled.main<{ isLoading: boolean }>`
  flex: 1 0 auto;
  height: 100vh;
  overflow: scroll;

  opacity: ${({ isLoading }) => (isLoading ? "0.25" : 1)};
  transition: opacity 200ms;
  transition-delay: 200ms;
`;
