import { Form, NavLink, useFetcher, useLoaderData } from "react-router-dom";
import styled from "styled-components";
import { FlexSpaceBetween } from "../styles/shared";
import CustomSpinner from "./CustomSpinner";

const Sidebar = () => {
  const fetcher = useFetcher();
  const isLoading = fetcher.state === "idle" ? false : true;

  // localStorage에 저장된 sites 목록.
  const { sites } = useLoaderData() as { sites: string[] };

  return (
    <CustomSpinner spinning={isLoading}>
      <Container id="sidebar">
        <Inner>
          <Title>payhere 프론트엔드 과제 (이동수)</Title>
          <fetcher.Form method="post" role="search">
            <FormContainer>
              <input placeholder="www.naver.com" type="search" name="input" />

              <button type="submit" disabled={isLoading}>
                추가
              </button>
            </FormContainer>
          </fetcher.Form>

          <Nav>
            {sites.length ? (
              <ul>
                {sites.map((site) => (
                  <Li key={site}>
                    <NavLink
                      to={`detail/${site}`}
                      className={({ isActive, isPending }) =>
                        isActive ? "active" : isPending ? "pending" : ""
                      }
                    >
                      {site}
                    </NavLink>
                    <Form method="post" action={`detail/${site}/delete`}>
                      <button type="submit">삭제</button>
                    </Form>
                  </Li>
                ))}
              </ul>
            ) : (
              <p>
                <i>등록된 관심 사이트가 없습니다.</i>
              </p>
            )}
          </Nav>
        </Inner>
      </Container>
    </CustomSpinner>
  );
};

export default Sidebar;

const Container = styled.aside`
  min-width: 19rem;
  background-color: ${({ theme }) => theme.colors.white000};
  border-right: solid 1px ${({ theme }) => theme.colors.white100};
  height: 100vh;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1rem 2rem;
  border-top: 1px solid ${({ theme }) => theme.colors.white100};
  order: 1;
  line-height: 1;
`;

const FormContainer = styled.div`
  ${FlexSpaceBetween}
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white100};
`;

const Nav = styled.nav`
  flex: 1;
  overflow: auto;
  padding-top: 1rem;
`;

const Li = styled.li`
  ${FlexSpaceBetween}
  margin: 0.25rem 0;

  & > a {
    padding: 0.5rem;
    border-radius: 8px;
  }

  & > a:hover {
    background-color: ${({ theme }) => theme.colors.white100};
  }

  & > a.active {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
  }

  & > a.pending {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
