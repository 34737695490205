import { SmileOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { FlexCenter } from "../styles/shared";

// 아무 관심 사이트도 클릭 하지 않았을 떄 렌더링 되는 컴포넌트.
const Index = () => {
  return (
    <Container>
      <Inner>
        <SmileOutlined style={{ fontSize: "3rem" }} />
        <Title>관심 사이트를 클릭해주세요!</Title>
      </Inner>
    </Container>
  );
};

export default Index;

const Container = styled.div`
  ${FlexCenter}
  width: 100%;
  height: 100%;
`;

const Inner = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h4`
  margin-top: 1rem;
`;
