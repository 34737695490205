// ex) 20130101073530를 보기 좋게 formatting 하는 함수.
const dateFormat = (data: string) => {
  const regExp = new RegExp(/^[0-9]{14}$/);
  const isCurrect = regExp.test(data);

  if (isCurrect) {
    const year = data.slice(0, 4);
    const month = data.slice(4, 6);
    const day = data.slice(6, 8);
    const hour = data.slice(8, 10);
    const minute = data.slice(10, 12);
    const second = data.slice(12, 14);

    return `${year}년 ${month}월 ${day}일 ${hour}:${minute}:${second}`;
  }
  return data;
};

export default dateFormat;
