import { message } from "antd";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { createSite, deleteSite, getLocalStorage, setLocalStorage } from "..";

// 관심 페이지 등록 시 동작.
export async function SidebarAction({ request }: { request: Request }) {
  try {
    const formData = await request.formData();
    const inputUrl = formData.get("input") as string;

    if (!inputUrl) {
      message.error("값을 입력해주세요.");
      return null;
    }

    const localStorage = getLocalStorage();

    if (localStorage.length >= 4) {
      message.error("더 이상 등록할 수 없습니다.");
    } else if (localStorage.includes(inputUrl)) {
      message.error("이미 존재하는 관심사이트입니다.");
    } else {
      const isCreatePossible = await createSite(inputUrl);

      if (isCreatePossible) {
        setLocalStorage(inputUrl);
        message.success("관심 사이트 등록에 성공했습니다.");
      } else {
        message.error("이용할 수 없는 사이트입니다.");
      }
    }

    return null;
  } catch (error) {
    message.error("오류가 발생했습니다. 다시 시도해주세요.");
  }
}

// 관심 페이지 삭제 시 동작.
export async function deleteAction({ params }: LoaderFunctionArgs) {
  const { url } = params;

  if (url) {
    deleteSite(url);
    return redirect("/");
  }
}
