import { css } from "styled-components";

export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexSpaceBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
