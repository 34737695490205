import { FrownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { FlexCenter } from "../styles/shared";

const Error = () => {
  return (
    <Container>
      <Inner>
        <Box>
          <FrownOutlined style={{ fontSize: "4rem", marginBottom: "2rem" }} />
          <i>죄송합니다. 예상치 못한 오류가 발생했습니다.</i>
        </Box>
      </Inner>
    </Container>
  );
};

export default Error;

const Container = styled.div`
  ${FlexCenter}
  height: 100vh;
`;

const Inner = styled.div`
  ${FlexCenter}
`;

const Box = styled.div`
  ${FlexCenter}
  flex-direction: column;
`;
