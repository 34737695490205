import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getSiteDetail } from "../api";
import DetailItem from "../components/DetailItem";
import Loading from "../components/Loading";
import dateFormat from "../utils/dateFormat";

const DetailItems = () => {
  const { url } = useParams() as { url: string };

  // 데이터를 무한 스크롤 하기 위해 서버에서 받아오는 resumeKey.
  const [key, setKey] = useState<string>("");

  // 렌더링 하는 링크 목록.
  const [itemLists, setItemLists] = useState<string[][]>([]);

  // 무한 스크롤 하기 위한 observer Ref.
  const observerTargetEl = useRef<HTMLDivElement>(null);

  // 최신화된 getMoreItems를 저장하기 위한 Ref.
  const funcRef = useRef<() => Promise<void>>();

  useEffect(() => {
    if (url) {
      setKey("");
      setItemLists([]);
    }
  }, [url]);

  useEffect(() => {
    if (!observerTargetEl.current) return;

    const io = new IntersectionObserver(async ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        if (funcRef.current) {
          await funcRef.current();
        }
        observer.observe(entry.target);
      }
    });
    io.observe(observerTargetEl.current);

    return () => {
      io && io.disconnect();
    };
  }, []);

  const getMoreItems = useCallback(async () => {
    const { detailList, resumeKey } = await getSiteDetail(url, key);
    setItemLists((prevItems) => prevItems.concat(detailList));
    setKey(resumeKey);
  }, [url, key]);

  funcRef.current = getMoreItems;

  return (
    <div>
      <Inner>
        <Flex>
          <Title>{url}</Title>
          <small>상세 조회 페이지입니다.</small>
        </Flex>
        <Ul>
          {itemLists.map((item, index) => {
            const date = dateFormat(item[0]);
            const url = `http://web.archive.org/web/${item[0]}/${item[1]}`;
            return <DetailItem key={index} url={url} date={date} />;
          })}
        </Ul>
        <Loading ref={observerTargetEl} />
      </Inner>
    </div>
  );
};

export default DetailItems;

const Inner = styled.div`
  padding: 30px;
`;

const Flex = styled.div`
  display: flex;
  align-items: baseline;
`;

const Title = styled.h1`
  margin-right: 0.5rem;
`;

const Ul = styled.ul`
  width: 100%;
`;
